import { useEffect } from "react";

function Keyframes({ animationName, keyframes, children }) {

    useEffect(() => {

        if (!keyframes) return () => {};

        let keyFrames = `
        @keyframes ${animationName}{
            ${Object.entries(keyframes).reduce((accum, [step, obj]) => {
                return accum += `${step} {
                ${Object.entries(obj).reduce((props, [key, value]) => props += `${key}: ${value};\n`, '')}
                }`
            }, '')}
        }`;

        const style = document.createElement('style');
        document.head.appendChild(style);
        style.appendChild(document.createTextNode(keyFrames));
        return () => style.remove();

    }, [animationName, keyframes])

    return ([children])
}

export default Keyframes;
