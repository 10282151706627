import useUserStore from "../../../../store/userStore";
import { Popover, PopoverTrigger, PopoverContent, PopoverBody } from "@chakra-ui/react";
import Button from "../../../Button/Button";
import styles from "./UserLogoutButton.module.scss";
import { useTranslation } from "react-i18next";

export default function UserLogoutButton() {
    const [user, logout] = useUserStore(state => [state.user, state.logout]);
    const {t} = useTranslation();

    return (
        <Popover closeOnBlur={true}>
        <PopoverTrigger>
            <img src={user.picture} className={styles.loggedInUser}/>
        </PopoverTrigger>
        <PopoverContent style={{width: 'auto', background:'rgba(255,255,255,0.5', border: 'none'}}>
            <PopoverBody><Button onClick={logout}>{t('logout')}</Button></PopoverBody>
        </PopoverContent>
        </Popover>
    )
}