import { useEffect, useState } from 'react';
import Bubble from "./Bubble.jsx";
import styles from "./Bubbles.module.scss"

function Bubbles() {

    const [bubbles, setBubbles] = useState({});

    function removeBubble(id) {
        setBubbles(bubbles => {
            delete bubbles[id];
            return bubbles;
        });
    }

    useEffect(() => {

        const randomBubbles = () => setInterval(() => {
            const bubbleId = Date.now();
            setBubbles(bubbles => ({...bubbles, [bubbleId]: <Bubble key={`bubble-${bubbleId}`} id={`bubble-${bubbleId}`} onGone={() => removeBubble(bubbleId)} />}));
        }, Math.random () * 5000 + 1);

        let interval = randomBubbles();

        document.addEventListener("visibilitychange", function() {
            if (document.hidden){
                console.log("Browser tab is hidden");
                clearInterval(interval)
            } else {
                console.log("Browser tab is visible")
                interval = randomBubbles();
            }
        });

        return () => clearInterval(interval);
    }, [])

    return (
        <div className={styles.bubbles}>
            {Object.values(bubbles)}
        </div>
    )
}

export default Bubbles;
