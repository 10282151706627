import { useCallback } from "react";
import styles from './Button.module.scss';

const BUTTON_CLICK_DELAY = 100;

export default function Button({children, onClick, type, color, className}) {

    const _onClick = useCallback((e) => {
        setTimeout(() => onClick(e), BUTTON_CLICK_DELAY);
    }, [onClick])

    let typeStyle = '';
    if (type === 'secondary') typeStyle = styles.secondary;

    let colorStyle = '';
    if (color === 'green') colorStyle = styles.green;

    return (
        <button className={`${styles.button} ${typeStyle} ${colorStyle} ${className ? className : ''}`} onClick={_onClick}>{children}</button>
    )
}