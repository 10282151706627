import { create } from 'zustand'
import useUserStore from './userStore';
import config from "../config.json";
import api from '../lib/api';
import useHallowenStore from './halloweenStore';

async function uploadFishy({aquariumId, connectToken, blob, maskUrl}) {
  try {
    let res;
    if (aquariumId)
      res = await api.uploadFishy(aquariumId, blob, maskUrl);
    else if (connectToken)
      res = await api.uploadFishyConnectToken(connectToken, blob, maskUrl);

    if (res.status == '429') {
      return {error: 'rate-limit', message: `rate limit exceeded`}
    }
  }
  catch (ex) {
    if (ex.response?.status === 500 && ex.response?.data?.code === 'aquarium_limit'){
      return {error: 'aquarium-limit', message: 'aquarium limit exceeded'}
    }
    return {error: ex.response?.status || true, message: ex.response?.data || ex.response?.statusText || `Error: ${ex}`}
  }
  return {}
}


const useFishyStore = create((set) => ({
  aquariumId: null,
  connectToken: null,
  connectedToOwnAquarium: false,
  fishys: [],
  fishyLoopRunning: false,
  capturedFishy: {},
  pendingUserAction: false,
  setConnectedToOwnAquarium: connectedToOwnAquarium => set({connectedToOwnAquarium}),
  addFishy: async (fishy) => {

    const state = useFishyStore.getState();
    const user = useUserStore.getState().serverUser;

    if (!state.connectedToOwnAquarium && state.connectToken) { //user connected to an external aquarium
      const res = await uploadFishy({connectToken: state.connectToken, blob: fishy.blob, maskUrl: fishy.maskUrl});
      if (res.error){ //only if there is an error uploading, add it locally.
        set ({fishys: state.fishys.concat([fishy])});
        if (res.error === 'aquarium-limit')
          return {status: 'external-aquarium-limit'}
        else 
          return {status: 'error'}
      }
    }
    else if (!user && state.fishys.length >= config.tiers['free-anonymous'].fishys) {
      set({pendingUserAction: 'login'});
      return {status: 'login'};
    }
    else if (state.aquariumId){ //own user aquarium
        let res;
        res = await uploadFishy({aquariumId: state.aquariumId, blob: fishy.blob, maskUrl: fishy.maskUrl});
        if (res.error) {
          if (res.error === 'aquarium-limit') {
            set({pendingUserAction: 'upgrade'});
            return {status: 'upgrade'}
          }
          else {
            set ({fishys: state.fishys.concat([fishy])});
            return {status: 'error'}
          }
        }
      }
    else { // not logged in user, and not connected to external aquarium
      return set ({fishys: state.fishys.concat([fishy])});
    }
    
  },
  serverFishys: [],
  setCapturedFishy: fishy => set({capturedFishy: fishy}),
  setAquariumId: aquariumId => {
    set({aquariumId});
    getFishyLoop(aquariumId);
  },
  setConnectToken: connectToken => {
    set({connectToken});
    console.log({connectToken});
    if (!useFishyStore.getState().aquariumId)
      getFishyLoopConnectToken(connectToken);
  },
  setServerFishys: serverFishys => set({serverFishys}),
  setFishyLoopRunning: fishyLoopRunning => set({fishyLoopRunning}),
  checkAndSetAquariumId: async (aquariumId) => {
    try {
      const res = await api.isAquariumExists(aquariumId);
      if (res.data?.connectToken) {
        useFishyStore.getState().setAquariumId(aquariumId);
        useFishyStore.getState().setConnectToken(res.data?.connectToken)
        return true;
      }
      else {
        useFishyStore.getState().setAquariumId(null);
        useFishyStore.getState().setConnectToken(null);
        return false;
      }
    }
    catch (ex) {
      console.log(ex);
      return false;
    }
  },
  checkAndSetConnectToken: async (connectToken) => {
    try {
      const res = await api.isAquariumExistsConnectToken(connectToken);
      if (res.data?.connectToken) {
        useFishyStore.getState().setConnectToken(connectToken);
        return true;
      }
      else {
        useFishyStore.getState().setConnectToken(null);
        return false;
      }
    }
    catch (ex) {
      console.log(ex);
      return false;
    }
  }
}));

const _getFishys = (apiRes) => {
  const {fishys, connectToken} = apiRes?.data || {};
  const state = useFishyStore.getState();
  if (JSON.stringify(state.serverFishys) !== JSON.stringify(fishys))
    
    ////halloween
    fishys.some(fishy => {
      if (fishy.maskUrl.includes('fish9') && !state.serverFishys.some(serverFishy => fishy.fileName === serverFishy.fileName))
        useHallowenStore.getState().setHalloweenFishyUploaded();
  })
  //////
    state.setServerFishys(fishys);
  if (connectToken != state.connectToken)
    state.setConnectToken(connectToken);
}

const getFishys = async (aquariumId) => {
  try {
      const res = await api.getFishys(aquariumId);
      _getFishys(res);
  }
  catch (ex) {
      //show message
      console.error (ex);
  }
};

const getFishysConnectToken = async (connectToken) => {
  try {
      const res = await api.getFishysConnectToken(connectToken);
      _getFishys(res);
  }
  catch (ex) {
      //show message
      console.error (ex);
  }
};

function getFishyLoop(aquariumId) {
  if (!useFishyStore.getState().fishyLoopRunning){
    getFishys(aquariumId);
    useFishyStore.getState().setFishyLoopRunning (true);
    setInterval (() => getFishys(aquariumId), config.fishySyncDuration);
  }
}

function getFishyLoopConnectToken(connectToken) {
  if (!useFishyStore.getState().fishyLoopRunning){
    getFishysConnectToken(connectToken);
    useFishyStore.getState().setFishyLoopRunning (true);
    setInterval (() => getFishysConnectToken(connectToken), config.fishySyncDuration);
  }
}

export default useFishyStore;