import {useEffect, useCallback, useMemo, useRef, useState} from "react";
import Keyframes from "../../Keyframes.jsx";
import styles from "./AnimatedFish.module.scss";
import Fish from "../Fish/Fish.jsx";

function random(from, to) {
    return Math.random() * (to - from) + from;
}

function generateVerticalAnimationKeyframes(height){
    const keyFrames = {
        "0": {
            "transform": "translateY(0) rotate(0deg)",
        },
        "100%": {
            "transform": `translateY(${random(30,60)}px) rotate(-${random(5,15)}deg)`,
        }
    }
    return keyFrames
}

function generateHorizontalAnimationKeyframes(width) {
    const direction = Math.random() > 0.5 ? "left" : "right";
    const keyFrames = {
        "0%": {
            [direction]: "100%",
            rotate: direction === "right" ? "y 180deg" : "0deg"
        },
        "50%": {
            [direction]: `-${width}px`,
            rotate: direction === "right" ? "y 180deg" : "0deg"
        },
        "51%": {
            [direction]: `-${width}px`,
            rotate: direction === "right" ? "0deg" : "y 180deg"
        },
        "100%": {
            [direction]: `calc(100% + ${width}px)`,
            rotate: direction === "right" ? "0deg" : "y 180deg"
        }
    }
    return keyFrames
}

function generateFishAnimationKeyframes() {
    const keyFrames = {
        "0%": {
            "rotate": "y 20deg",
            "scale": "0.95"
        },
        "50%": {
            "rotate": "y 0deg",
            "scale": "1"
        },
        "100%": {
            "rotate": "y -20deg",
            "scale": "1.05"
        }
    }
    return keyFrames
}

function AnimatedFish({ remove, fileUrl, maskUrl }){

    const [timeToRefresh, refresh] = useState(random(15,25));
    const [travels, setTravels] = useState(random(11,18));
    const width = useMemo (() => random(130, 250), []);

    const verticalAnimationName = useMemo (() => `fish-animation-vertical-${Date.now()}`, [timeToRefresh]);
    const horizontalAnimationName = useMemo (() => `fish-animation-horizontal-${Date.now()}`, [timeToRefresh]);
    const FishAnimationName = useMemo (() => `fish-animation-${Date.now()}`, [timeToRefresh]);
    const verticalAnimationKeyframes  = useMemo(() => generateVerticalAnimationKeyframes(), [timeToRefresh]);
    const horizontalAnimationKeyframes  = useMemo(() => generateHorizontalAnimationKeyframes(width), [timeToRefresh]);
    const fishAnimationKeyframes  = useMemo(() => generateFishAnimationKeyframes(), [timeToRefresh]);
    const fishStyle = useMemo(() => ({
        animationName: FishAnimationName,
        animationDuration: `${random(1,4)}s`,
        animationIterationCount: "infinite",
        animationDirection: "alternate",
        animationTimingFunction: "ease-in-out",
        width
    }), [timeToRefresh]);
    const verticalStyle = useMemo(() => ({
        animationName: verticalAnimationName,
        animationDuration: `${random(3,5)}s`,
        animationIterationCount: "infinite",
        animationDirection: "alternate",
        animationTimingFunction: "ease-in-out",
        width
    }), [timeToRefresh]);
    const horizontalStyle = useMemo(() => ({
        animationName: horizontalAnimationName,
        animationDuration: `${timeToRefresh}s`,
        animationIterationCount: "1",
        animationDirection: "normal",
        animationTimingFunction: "linear",
        animationFillMode: "forwards",
        top: `${random(0,80)}%`,
        transformOrigin: "center",
        width
    }), [timeToRefresh])

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (travels > 0) {
                setTravels(travels => travels--);
                refresh(random(15,25));
            }
            else {
                remove && remove();
            }
        }, timeToRefresh * 1000 + 2000);

        return () => clearTimeout(timeout);
    }, [timeToRefresh])

    return (
        <div className={styles.fishContainer}>
            <Keyframes animationName={horizontalAnimationName} keyframes={horizontalAnimationKeyframes}>
                <Keyframes animationName={verticalAnimationName} keyframes={verticalAnimationKeyframes}>
                    <Keyframes animationName={FishAnimationName} keyframes={fishAnimationKeyframes}>
                        <div className={styles.horizontalMovement} style={horizontalStyle}>
                            <div className={styles.verticalMovement} style={verticalStyle}>
                                <div style={fishStyle}>
                                    <Fish fileUrl={fileUrl} maskUrl={maskUrl} width={width}/>
                                </div>
                            </div>
                        </div>
                    </Keyframes>
                </Keyframes>
            </Keyframes>
        </div>
    );
}

export default AnimatedFish;

