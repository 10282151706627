const errors = {
    "MARKERS_NOT_DETECTED": {
        message: "Markers not detected"
    },
    "FISHY_NOT_FOUND": {
        message: "Markers not detected"
    },
    "UNKNOWN_ERROR": {
        message: "Unknown Error"
    },
    "USER_NOT_LOGGED_IN": {
        message: "User not logged in"
    },
    throw (err) {
        let error = new Error();
        Object.assign (error, errors[err] || errors.UNKNOWN_ERROR);
        console.error (error.stack);
        throw error;
    }
}
Object.keys (errors).forEach(err => errors[err].code = err);
Object.freeze(errors);
export default errors;
