import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import { BrowserRouter as Router } from 'react-router-dom';
import "./lib/18n";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ChakraProvider } from '@chakra-ui/react'
import { extendTheme } from "@chakra-ui/react"
import theme from './theme';
import FishyRoutes from './Routes/Routes';


const customTheme = extendTheme(theme);
const GOOGLE_CLIENT_ID = '89736342506-fn6p1unmlcv06tent75gfs3qbmkphn6g.apps.googleusercontent.com'

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <ChakraProvider theme={customTheme}>
        <Router>
          <FishyRoutes />
        </Router>
      </ChakraProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>,
)
