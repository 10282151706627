import styles from './Loading.module.scss';
import useLoading from '../../store/useLoading';

export default function Loading() {
    const show = useLoading(state => state.show);

    if (!show) return null;

    return (
        <div className={styles.backdrop}>
            <div className={styles.loading}>
                <img src='/favicon-32x32.png' />
            </div>
        </div>
    )
}