import "./styles/globals.scss";
import styles from './App.module.scss';
import { useTranslation } from "react-i18next";

import Aquarium from "./components/Aquarium/Aquarium.jsx";
import MenuHeader from './components/MenuHeader/MenuHeader.jsx';
import { MenuItem } from './components/MenuHeader/Menu/Menu.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faPlug, faPrint } from '@fortawesome/free-solid-svg-icons';
import { Outlet, useParams } from 'react-router-dom';
import Loading from "./components/Loading/Loading.jsx";
import useLoading from "./store/useLoading.js";
import { useEffect, useRef } from "react";
import ErrorBoundary from "./components/ErrorBoundary.jsx";
import useRouter from "./store/useRouter.js";
import md from "./lib/MobileDetect.js";

export default function App({loading}) {
    const { t } = useTranslation();
    const { id, connectToken } = useParams();
    const inputRef = useRef();
    const setShow = useLoading(state => state.setShow);
    const setBase = useRouter(state => state.setBase);

    useEffect(() => {
        if (id) {
            setBase(`/aquarium/${id}`);
        }
        else if (connectToken) {
            setBase(`/c/${connectToken}`);
        }
    }, [id, connectToken])

    useEffect(() => {
        if (loading) setShow(true)
        return () => setShow(false);
    }, [loading])

    const uploadMenuItem = (md.mobile() || md.tablet()) ? 
    <MenuItem key='capture' linkTo="/capture"><div className={styles.menuItemIcon}><FontAwesomeIcon icon={faCamera} size="2x" /></div> {t('scan')}</MenuItem> :
    <MenuItem key='upload' linkTo="/upload"><div className={styles.menuItemIcon}><FontAwesomeIcon icon={faCamera} size="2x" /></div> {t('upload')}</MenuItem>

    return (
        <div className="App">
            <ErrorBoundary>
                <MenuHeader menuItems={
                    [uploadMenuItem,
                    <MenuItem key='print' linkTo={"/print"}><div className={styles.menuItemIcon}><FontAwesomeIcon icon={faPrint} size="2x" /></div> {t('print')}</MenuItem>,
                    <MenuItem key='share' linkTo={"/share"}><div className={styles.menuItemIcon}><FontAwesomeIcon icon={faPlug} size="2x" /></div> {t('share')}</MenuItem>]
                } />
                <Aquarium id={id} connectToken={connectToken} />

                {/* see main.js for router */}
                <Outlet /> 
                <Loading />
            </ErrorBoundary>
        </div>
    )
}