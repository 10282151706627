import useUserStore from '../../../store/userStore';
import LoginButton from './LoginButton/LoginButton';
import UserLogoutButton from './UserLogoutButton/UserLogoutButton';

export default function Login () {

    const user = useUserStore(state => state.user);

    if (user) {
        return <UserLogoutButton />
    }
    else {
        return <LoginButton />
    }
}