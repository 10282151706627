import { faExpand } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback } from "react";
import styles from './FullScreen.module.scss';

export default function FullScreen({aquariumRef}) {

    const _onClick = useCallback(() => {
        if (document.fullscreenElement) {
            document.exitFullscreen();
        }
        else {
            aquariumRef.requestFullscreen();
        }
    });

    return (
        <FontAwesomeIcon icon={faExpand} size="2x" onClick={_onClick} className={styles.fullScreen} />
    )
}