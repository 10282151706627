import { GoogleLogin } from '@react-oauth/google';
import styles from './LoginButton.module.scss';
import useUserStore from '../../../../store/userStore';
import { useCallback } from 'react';
import { useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export default function LoginButton() {
    const loginSuccess = useUserStore(state => state.loginSuccess);
    const toast = useToast();
    const {t} = useTranslation();
    const { id } = useParams();

    const onLoginSuccess = useCallback(async (...args) => {
        const success = await loginSuccess(...args);
        if (!success) {
            toast({
                title: t('loginError'),
                status: 'error',
                duration: 4000,
              })
        }
    }, []);

    const onError = useCallback((ex) => {
        console.log(`Login Failed: ${ex}`);
    }, [])

    return (
        <div className={styles.loginButton}>
            <GoogleLogin
                onSuccess={onLoginSuccess}
                onError={onError}
                text="signin"
                shape='pill'
                useOneTap={!id}
                auto_select={true}
            />
        </div>
    )
}