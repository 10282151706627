import { Suspense, lazy } from 'react';
const Upgrade = lazy(() => import('../pages/Upgrade/Upgrade'));
const Share = lazy(() => import('../pages/Share/Share'));
const Upload = lazy(() => import('../pages/Upload/Upload')) ;
const Capture = lazy(() => import('../pages/Upload/Capture/Capture'));
const Review = lazy(() => import('../pages/Upload/Review/Review'));
const PrintModal = lazy(() => import('../pages/Print/Print'));
const LoginModal = lazy(() => import('../pages/LoginModal/LoginModal'));
import App from '../App';

import { Route, Routes } from "react-router-dom";

function FishyRoutes() {
  return (
        <Suspense fallback={<App loading/>}>
          <Routes>
            <Route path="/" element={<App />} >
                <Route path="/upload" element={<Upload />} />
                <Route path="/capture" element={<Capture />} />
                <Route path="/capturing" element={<Review />} />
                <Route path="/print" element={<PrintModal />} />
                <Route path="/login" element={<LoginModal />} />
                <Route path="/upgrade" element={<Upgrade />} />
                <Route path="/share" element={<Share />} />
            </Route>
            <Route path="/aquarium/:id" element={<App />} >
              <Route path="/aquarium/:id/upload" element={<Upload />} />
              <Route path="/aquarium/:id/capture" element={<Capture />} />
              <Route path="/aquarium/:id/capturing" element={<Review />} />
              <Route path="/aquarium/:id/print" element={<PrintModal />} />
              <Route path="/aquarium/:id/login" element={<LoginModal />} />
              <Route path="/aquarium/:id/upgrade" element={<Upgrade />} />
              <Route path="/aquarium/:id/share" element={<Share />} />
            </Route>
            <Route path="/c/:connectToken" element={<App />}>
              <Route path="/c/:connectToken/upload" element={<Upload />} />
              <Route path="/c/:connectToken/capture" element={<Capture />} />
              <Route path="/c/:connectToken/capturing" element={<Review />} />
              <Route path="/c/:connectToken/print" element={<PrintModal />} />
              <Route path="/c/:connectToken/login" element={<LoginModal />} />
              <Route path="/c/:connectToken/upgrade" element={<Upgrade />} />
              <Route path="/c/:connectToken/share" element={<Share />} />
            </Route>
            <Route path="*" element={<App />} />
          </Routes>
        </Suspense>
     
  )
}

export default FishyRoutes;