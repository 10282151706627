import { googleLogout } from '@react-oauth/google';
import { create } from 'zustand';
import api from '../lib/api';
import useFishyStore from './fishys';

const parseJwt = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
      atob(base64)
          .split('')
          .map((c) => {
              return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join('')
  );
  return JSON.parse(jsonPayload);
};

const useUserStore = create((set) => ({
  user: null,
  serverUser: null,
  loginSuccess: async (credentials) => {
    try {
      const parsedJwt = parseJwt(credentials?.credential);
      const user = {...{idToken: credentials}, ...parsedJwt};
      const serverUser = await api.userLoggedIn(credentials);
      const fishyStore = useFishyStore.getState();
      if (serverUser.data) {
        if (!fishyStore.aquariumId && !fishyStore.connectToken){
          fishyStore.setAquariumId(serverUser.data.user?.accounts[0]?.account?.aquariums[0]?.token);
          fishyStore.setConnectToken(serverUser.data.connectToken);
          fishyStore.setConnectedToOwnAquarium(true);
        }
        set({user, serverUser: serverUser.data.user});
        return true;
      }
      else {
        console.error(`loginSuccess handler error`);
        return false;
      }
    }
    catch (ex) {
      console.error(`loginSuccess handler error: ${ex}`);
      return false;
    }
  },
  logout: () => {
    googleLogout()
    return set ({user: null});
  }
}));

export default useUserStore;