import { useMemo } from "react";
import styles from "./Bubble.module.scss";

function Bubble ({id, onGone}) {
    const size = Math.random() * 30 + 20;
    const style = useMemo(() => ({
        left: `${Math.random() * 99}%`,
        width: size,
        height: size,
        zIndex: Math.ceil(Math.random() * 99 + 1),
        animation: `bubble-go-up ${Math.ceil(Math.random() * 5 + 3)}s 1 forwards linear, bubble-go-sideways-${Math.ceil(Math.random() * 2 + 1)} ${Math.ceil(Math.random() + 0.3)}s infinite forwards alternate ease-in-out`,
    }));

    return (
        <img alt="bubble" key={id} id={id} src={"/assets/bubble.svg"} className={styles.bubble} style={style} onAnimationEnd={onGone}/>
    )
}

export default Bubble;
