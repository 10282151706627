import styles from "./Fish.module.scss";

export default function Fish( {fileUrl, maskUrl, width, className} ) {
    const fishStyle = {
        WebkitMaskImage: `url(${maskUrl})`,
        width
    }
    return (
        <img src={fileUrl} className={`${styles.fish} ${className || ''}`} style={fishStyle}/>
    )
}