import { ReactNode, useState } from "react";
import Header from "./Header/header";
import Menu from "./Menu/Menu";

export default function MenuHeader({menuItems}) {
    const [menuVisible, setMenuVisible] = useState(true);
    return (
        <>
            <Header menuClicked={() => setMenuVisible(vis => !vis)}/>
            <Menu displayed={menuVisible} closeMenuClicked={() => setMenuVisible(false)} >{menuItems}</Menu>
        </>
    );
}